.ql-picker-arial {
    font-family: Arial, sans-serif;
  }
  .ql-picker-courier-new {
    font-family: "Courier New", monospace;
  }
  .ql-picker-georgia {
    font-family: Georgia, serif;
  }
  .ql-picker-helvetica {
    font-family: Helvetica, sans-serif;
  }
  .ql-picker-lucida-sans {
    font-family: "Lucida Sans", sans-serif;
  }
  .ql-picker-times-new-roman {
    font-family: "Times New Roman", Times, serif;
  }
  .ql-picker-verdana {
    font-family: Verdana, sans-serif;
  }
  .ql-picker-monospace {
    font-family: monospace;
  }
  .ql-picker-cursive {
    font-family: cursive;
  }
  .ql-picker-fantasy {
    font-family: fantasy;
  }
  .ql-picker-impact {
    font-family: Impact, Charcoal, sans-serif;
  }
  .ql-picker-comic-sans-ms {
    font-family: "Comic Sans MS", cursive, sans-serif;
  }
  .ql-picker-roboto {
    font-family: "Roboto", sans-serif;
  }
  .ql-picker-poppins {
    font-family: "Poppins", sans-serif;
  }
  .ql-font-montserrat {
    font-family: "Montserrat";
  }
  .ql-picker-amiri {
    font-family: "Amiri Arabic";
  }
  .ql-picker-mb-lateefi {
    font-family: "MB Lateefi";
  }
  .ql-picker-al-qalam-quran-majeed-web {
    font-family: "Al Qalam Quran Majeed Web";
    /* font-family: 'Courier New', Courier, monospace; */
  }
  .ql-picker-jameel-noori-nastaleeq {
    font-family: "Jameel Noori Nastaleeq";
}